import { ModuleWithProviders, NgModule, inject } from '@angular/core';
import { LiveStreamsService } from './live-streams.service';
import { SlugService } from '@tytapp/slugs';
import { ApiLiveStream } from '@tytapp/api';
import { LIVE_STREAM_BLOCK_TYPE } from './live-stream-block';
import { ContentBlocksService } from '@tytapp/content';

@NgModule()
export class LiveStreamsModule {
    private liveStreams = inject(LiveStreamsService);

    constructor() {
        SlugService.registerType<ApiLiveStream>({
            type: 'LiveStream',
            friendlyName: 'Live Stream',
            getUrl: slug => `/live/streams/${slug.slug}`,
            getContent: async slug => await this.liveStreams.get(Number(slug.slug)),
            getMetadataForContent: async content => ({
                title: content.title ?? `#${content.id}`,
                url: `/live/streams/${content.id}`
            })
        });
        ContentBlocksService.register(LIVE_STREAM_BLOCK_TYPE);
        ContentBlocksService.registerConditionVariable({
            name: 'isLive',
            label: 'Currently Live',
            type: 'boolean',
            value: () => this.liveStreams.getLiveIndicator()
        });
    }

    static forRoot(): ModuleWithProviders<LiveStreamsModule> {
        return {
            ngModule: LiveStreamsModule,
            providers: [
                LiveStreamsService
            ]
        }
    }
}