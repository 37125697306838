import { ContentBlockType } from '@tytapp/content';
import { LiveStreamBlock } from './live-stream-block';

export * from './live-stream-block';

export const LIVE_STREAM_BLOCK_TYPE: ContentBlockType<LiveStreamBlock> = {
    id: 'live-stream',
    label: 'Live Stream',
    icon: 'smart_display',
    template: {
        prefer: 'auto',
        autoplay: true
    },
    preview: {
        prefer: 'auto',
        autoplay: true
    },
    deferredEditorComponent: () => import('./live-stream-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./live-stream-block.view').then(m => m.ViewComponent)
}
